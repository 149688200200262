<template>
  <header class="nav">
    <div class="inner">
      <div class="logo">
        <a href="/">
          <img src="/imgs/logo3.png" />
        </a>
      </div>
      <div class="links">
        <button @click="home" :class="{ active: activeIndex == 0 }">
          Home
        </button>
        <button
          @click="scrollTo('service', 1)"
          :class="{ active: activeIndex == 1 }"
        >
          Services
        </button>
        <button
          @click="scrollTo('about', 2)"
          :class="{ active: activeIndex == 2 }"
        >
          About Us
        </button>
        <button
          @click="scrollTo('contact', 3)"
          :class="{ active: activeIndex == 3 }"
        >
          Contact
        </button>
        <button @click="policy">Policy</button>
      </div>
      <!-- <div>
        <a href="/policy" class="buy-btn">Privacy Policy</a>
      </div> -->
    </div>
  </header>
  <router-view />
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
let activeIndex = ref(0);
const home = () => {
  activeIndex.value = 0;
  window.scroll({ top: 0, behavior: "smooth" });
};
const router = useRouter();
const scrollTo = (el, index, speed) => {
  activeIndex.value = index;
  if (!speed) speed = 200;
  const targetElement = document.getElementById(el);
  if (targetElement) {
    window.scroll({ top: targetElement.offsetTop - 96, behavior: "smooth" });
  }
};
const policy = () => {
  router.push("/policy");
};
</script>
<style lang="scss">
.nav {
  height: 96px;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 0 100px;
  .logo {
    width: 320px;
    color: #fff;
    img {
      width: 300px;
    }
  }
  .inner {
    min-width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links {
    position: relative;
    button {
      margin-right: 40px;
      color: #b0b0b0;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      border: 0;
      outline: 0;
      height: 60px;
      background: transparent;
    }
    button.active {
      color: #fff;
    }
  }
  .buy-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border: 0;
    color: #fff;
    font-size: 16px;
  }
}
</style>
