<template>
  <div class="home">
    <div class="hero">
      <div class="banner"></div>
      <div class="cover"></div>
      <div class="sloga">
        <div class="container">
          <h1 class="sloga-h1">Web 3.0 - The Future of Data Storage</h1>
          <h2 class="sloga-h2">Comprehensive Service Provider for Decentralized Data Storage</h2>
          <div class="desc">
            <p>As a distributed storage provider, MinerVerse is dedicated to provide customers with secured and intuitive one-stop</p>
            <p>solution for Filecoin mining and data storage. With our experienced technical team and world class products and services, We are commited to be part of the Web3.0 exploration and enhance the growth of blockchain economy.</p>
          </div>
        </div>
      </div>
    </div>
    <section class="block" id="service">
      <div class="container">
          <div class="block-title">
            <div>Services</div>
            <div class="block-title-line"></div>
          </div>
          <div class="services">
            <div class="service">
              <img src="/imgs/s1.png">
              <h2>Filecoin Mining</h2>
              <p>One-stop Solution</p>
              <p>for investors who wants to participate in the Filecoin mining activity</p>
            </div>
            <div class="service">
              <img src="/imgs/s3.png">
              <h2>IPFS Node Establishment/Configuration</h2>
              <p>One-stop Service Provider</p>
              <p>for blockchain operation and configuration of full nodes, establishment and development of open API</p>
            </div>
            <div class="service">
              <img src="/imgs/s2.png">
              <h2>Node Staking Service</h2>
              <p>Our Validator Nodes</p>
              <p>can generate stable PoS revenue with staking tokens</p>
            </div>
          </div>
      </div>
    </section>
    <!-- <section class="block">
      <div class="container">
          <div class="block-title">
            <div>Operation and Maintenance - Key Stats</div>
            <div class="block-title-line"></div>
          </div>
          <div class="services power">
            <div class="service ks">
              <img src="/imgs/1.png">
              <h2>0</h2>
              <p>Security Incidents</p>
            </div>
            <div class="service ks">
              <img src="/imgs/2.png">
              <h2>10+</h2>
              <p>IDCs</p>
            </div>
            <div class="service ks">
              <img src="/imgs/3.png">
              <h2>100000+</h2>
              <p>Managed Servers</p>
            </div>
            <div class="service ks">
              <img src="/imgs/4.png">
              <h2>600P+</h2>
              <p>Computing Power</p>
            </div>
          </div>
      </div>
    </section> -->
    <section class="block">
      <div class="container">
        <div class="block-title">
          <div>Filecoin’s Economic Model</div>
          <div class="block-title-line"></div>
        </div>
        <div class="linechart">
          <div id="linechart"></div>
          <div class="pie-desc">
            <h2>The total Filecoin supply is capped at 2 billion tokens.</h2>
            <ul>
              <li>
                <div class="dot" style="background: rgb(60, 81, 250);"></div>
                <span class="percent">55%</span>
                <span>Mining Rewards</span>
              </li>
              <li>
                <div class="dot" style="background: rgb(45, 162, 216);"></div>
                <span class="percent">15%</span>
                <span>Mining Reserve</span>
              </li>
              <li>
                <div class="dot" style="background: rgb(87, 210, 184);"></div>
                <span class="percent">10.5%</span>
                <span>Protocol Labs</span>
              </li>
              <li>
                <div class="dot" style="background: rgb(238, 203, 136);"></div>
                <span class="percent">10%</span>
                <span>Investors</span>
              </li>
              <li>
                <div class="dot" style="background: rgb(227, 124, 90);"></div>
                <span class="percent">5%</span>
                <span>Filecoin Foundation</span>
              </li>
              <li>
                <div class="dot" style="background: rgb(182, 86, 214);"></div>
                <span class="percent">4.5%</span>
                <span>Team and Contributors</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="block" id="about">
      <div class="container">
        <div class="block-title">
          <div>About Us</div>
          <div class="block-title-line"></div>
        </div>
        <div class="about">
          <h2>Top Service Provider for Filecoin Mining</h2>
          <p>Minerverse is dedicated to provide our clients comprehensive solutions for Filecoin mining and staking. By utilizing our secured, advance, and intuitive technology, we are committed to participate ourselves in the Web3.0 ecosystem and try to grow the system with our community as well. Minerverse currently has started expanding our services into the North American market and by using our strong supply chain system, we believe that we can provide the best service to our clients in North America.</p>
        </div>
      </div>
    </section>
    <section class="block">
      <div class="container">
        <div class="block-title">
          <div>IDCs and Servers</div>
          <div class="block-title-line"></div>
        </div>
        <div style="position: relative;">
          <div class="swiper" id="slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide" data-swiper-slide-index="2" style="width: 1200px;">
                <div data-v-b9ca3e10="" class="item">
                    <img data-v-b9ca3e10="" src="/imgs/5.png" style="width: 524px; height: 369px;">
                    <div data-v-b9ca3e10="" style="margin-left: 620px; padding-right: 100px;">
                      <h2 data-v-b9ca3e10="">Storage Servers</h2>
                      <p data-v-b9ca3e10="" style="line-height: 30px;">Our storage servers consist of a 16T enterprise-grade helium HDD that ensures high read and write speeds, a long lifespan and low per-terabyte storage costs. They also come with distributed storage technology to guarantee the security and integrity of your data.</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide" style="width: 1200px;">
                <div class="item">
                  <img src="/imgs/6.png" style="width: 524px; height: 369px;">
                  <div style="margin-left: 620px; padding-right: 100px;">
                    <h2>IDCs</h2>
                    <p>Internet data centers built to the highest national security standards.</p>
                    <p>2T Internet access, T3+ data center, enclosed cold aisle temperature control.</p>
                    <p>Dual mains input + diesel generator + UPS power supply.</p>
                    <p>High bandwidth to meet Blockchain network requirements.</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide" style="width: 1200px;">
                <div class="item">
                  <img src="/imgs/7.png" style="width: 524px; height: 369px;">
                  <div style="margin-left: 620px; padding-right: 100px;">
                    <h2>Computing Servers</h2>
                    <p style="line-height: 30px;">With a high-end AMD7002 series processor, NVIDIA’s flagship RTX30 graphics card and a high-performance NVMe cache drive, our computing servers use an algorithm optimizer to deliver a more than two-fold increase in efficiency compared with the official code.</p>
                  </div>
                </div>
              </div>
            </div>  
            <div class="swiper-button-prev"></div> 
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="block">
      <div class="container">
        <div class="block-title">
          <div>Why Choose Filecoin?</div>
          <div class="block-title-line"></div>
        </div>
        <div class="why">
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">1</div>
              <h2>Strong Investor Background</h2>
              <p>Venture Capitals such as Sequoia Capital, Y Combinator and Union Square Ventures are all participants of Filecoin network.</p>
            </div>
          </div>
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">2</div>
              <h2>Experienced Founders' Team</h2>
              <p>The IPFS launched by Protocol Labs has been described as "the future of the web". The founders team also participated in IPLD, libp2p, Orbit, and multiformats.</p>
            </div>
          </div>
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">3</div>
              <h2>Comprehensive and Innovative Economic Model</h2>
              <p>More than staking the token, Filecoin asks the miners to participate in the network over an extended period of time in order to acquire full reward to ensure the stableness of the network.</p>
            </div>
          </div>
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">4</div>
              <h2>High Demand</h2>
              <p>As the mainnet launched, Filecoin was listed on majority of the trading platforms such as Coinbase, Binance, Huobi, and OKEx. Recently, the demand for mining Filecoin has surged tremendously.</p>
            </div>
          </div>
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">5</div>
              <h2>Useful Application in the Future</h2>
              <p>Filecoin is the infrastructure of Web3.0 that has the potential to establish decentralized DropBox and Amazon Cloud Server for the customers.</p>
            </div>
          </div>
          <div class="why-item">
            <div class="why-item-inner">
              <div class="dot">6</div>
              <h2>Pioneering Distributed Storage Technology</h2>
              <p>Filecoin is one of the world’s leading projects for distributed storage, a key component of Web 3.0.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="block contact" id="contact">
      <div class="container">
        <div class="block-title">
          <div>Contact Us</div>
          <div class="block-title-line"></div>
        </div>
        <div class="cod">
          If you require any of the above services, please do not hesitate to get in touch with us.
        </div>
        <div class="contact-wraper">
          <div class="mainbox">
            <p>hello@minerverse.net</p>
            <a href="mailto: hello@minerverse.net">Send Email</a>
          </div>
        </div>
        <div class="channels">
          <div class="ch">
            <p class="area">HongKong</p>
            <p class="con">Email: hk@minerverse.net</p>
          </div>
          <div class="ch">
            <p class="area">North America</p>
            <p class="con">Email: northamerica@minerverse.net</p>
          </div>
          <div class="ch">
            <p class="area">Singapore</p>
            <p class="con">Email: singapore@minerverse.net</p>
          </div>
        </div>
        <div class="bt">
          <p>
            &copy;2022 MinerVerse Inc.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  const router = useRouter()
  onMounted(()=>{
    initLineChart()
    initSwiper()
  })
  const about = ()=>{
    router.push({name:'About'})
  }
  function initSwiper(){
    const swiper = new Swiper('.swiper', {
      loop: true,
      autoplay:{
        delay: 5000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
  }
  function initLineChart(){
    let myChart = echarts.init(document.getElementById('linechart'))
    let option = {
      tooltip: {
          trigger: "item",
          formatter: "{b} : {d}%",
          backgroundColor: "#100F18",
          borderWidth: 0,
          textStyle: {
              color: "#fff"
          }
      },
      legend: {
          show: false
      },
      series: [{
          name: "Filecoin",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
              show: false
          },
          emphasis: {
              label: {
                  show: false
              }
          },
          labelLine: {
              show: false
          },
          data: [
            { value: 55, name: 'Mining Rewards' },
            { value: 15, name: 'Mining Reserve' },
            { value: 10.5, name: 'Protocol Labs' },
            { value: 10, name: 'Investors' },
            { value: 5, name: 'Filecoin Foundation' },
            { value: 4.5, name: 'Team and Contributors' },
          ],
          itemStyle: {
              emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
              },
              normal: {
                  color: function(e) {
                      var t = ["#3C51FA", "#2DA2D8", "#57D2B8", "#EECB88", "#E37C5A", "#B656D6"];
                      return t[e.dataIndex]
                  }
              }
          }
      }]
    }
    myChart.setOption(option)
  }
</script>
<style lang="scss" scoped>
  @import './home.scss';
</style>
